document.addEventListener(
    "DOMContentLoaded",
    function () {
    //   import("./blocks/core/query").then((module) => {      
    //       module.init();
    //   });

    console.log('app.js loaded');
    
    }
  );